<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="#23303D"
      stroke-linecap="round"
      stroke-width="1.5"
      d="M12.495 2.491l-1.62 1.74-1.62-1.74C7.5.604 4.543.494 2.65 2.244c-.086.08-.168.161-.248.247-1.87 1.993-1.87 5.088 0 7.08L11 18.392l8.598-8.82c1.87-1.992 1.87-5.087 0-7.08-1.915-1.955-5.06-1.993-7.02-.082l-.083.082z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
